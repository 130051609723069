import * as React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import Layout from "../components/layout"
import Contact from "../components/contact"
import Seo from "../components/seo"
import { val }  from '../components/cssvalue'
import {MainWrap, TablePlans, AreaSmall} from '../pages/web.js'
import { media } from '../components/style-utils';

// STYLE

const TablePlansOperate = styled(TablePlans)`
  th {
    &:nth-child(2) {
      width: 120px;
      ${media.sp`
        width: 15vw;
      `}
    }
    &:nth-child(3) {
      width: 120px;
      ${media.sp`
        width: 15vw;
      `}
    }
  }
`;


const SectionDetail = styled.section`
  > .title {
    ${media.sp`
      padding: 0 5vw;
      font-size: 5vw;
    `}
  }
  > .lead {
    ${media.sp`
      padding: 0 5vw;
      font-size: 4vw;
    `}
  }
`;

const SectionArticlePrice = styled.section`
`;
const Operate = ({data, location}) => {
  return (
    <Layout location={location}>
      <Seo
        title="ホームページ運用・改善・アクセスアップ・SEO対策　一覧と詳細"
        description="神奈川でホームページ作成TAN-ZENTのホームページ運用プラン詳細です。"
        pagepath={location.pathname}
      />
      <MainWrap>
        <div className="-jacket">
          <h1>ホームページ運用・改善・アクセスアップ・SEO対策 詳細ページ</h1>
          <p className="lead">
            ホームページは適切に運用をすることで、より成果を出すことができます。<br />
            アクセス数や売り上げを上げたいときにどうすればいいのか、運用経験20年以上のプロにお任せください。<br />
            御社内で対応するスタッフがいない場合や、プロによる対応が必要な場合など、お気軽にご相談ください。
          </p>
          <SectionDetail>
            <h2 className="title">ホームページ運用</h2>
            <p className="lead">
              簡単な修正1点から保守運用管理全般まで、ホームページに関わることならなんでもお任せください。
            </p>
            <TablePlansOperate className="-option">
              <caption>ホームページ運用</caption>
              <thead>
                <tr>
                  <th>オプション名</th>
                  <th>スポット<br />（1回のみ）価格</th>
                  <th>サブスクリプション月額</th>
                  <th>内容</th>
                </tr>
              </thead>
              <tbody className="table-pluns-system">
                <tr>
                  <th>テキスト修正等</th>
                  <td>1,100円〜</td>
                  <td>5,500円〜</td>
                  <td>テキストや画像の差し替え作業<br />※差し替えるものをご用意頂いた場合</td>
                </tr>
                <tr>
                  <th>既存ホームページ改善提案</th>
                  <td>22,000円〜</td>
                  <td>ー</td>
                  <td>ホームページが目的通りに機能しているか、より成果を出すための改善提案をさせていただきます。ホームページの規模や内容により料金が異なります。</td>
                </tr>
                <tr>
                  <th>SEOコンサル</th>
                  <td>22,000円〜</td>
                  <td>ー</td>
                  <td>ホームページを分析して検索エンジンで上位表示させるためのアドバイスをおこないます。</td>
                </tr>
                <tr>
                  <th>アクセス解析・レポート</th>
                  <td>ー</td>
                  <td>10,000円〜</td>
                  <td>毎月のアクセス数や流入、閲覧しているページ等のレポートや改善点をご提出させていただきます。</td>
                </tr>
                <tr>
                  <th>CMS管理</th>
                  <td>ー</td>
                  <td>10,000円〜</td>
                  <td>サーバー保守管理やCMSのアップデート等システム管理をおこないます。</td>
                </tr>
                <tr>
                  <th>運用代行</th>
                  <td>ー</td>
                  <td>50,000円〜</td>
                  <td>毎月のホームページの更新を代行しておこないます。原稿をいただいてCMS更新作業はこちらでおこなうなど、ネットショップの商品登録等もご相談ください。</td>
                </tr>
                <tr>
                  <th>保守運用管理</th>
                  <td>ー</td>
                  <td>50,000円〜</td>
                  <td>御社の外部パートナーとして、ホームページだけではなくメールの設定や外部業者との折衝、ITに関わるご相談など丸ごと対応いたします。</td>
                </tr>
                <tr>
                  <th>その他</th>
                  <td>ご相談</td>
                  <td>ご相談</td>
                  <td>ホームページに関することならなんでもご相談ください。</td>
                </tr>
              </tbody>
            </TablePlansOperate>
          </SectionDetail>
          <SectionDetail>
            <h2 className="title">ホームページアクセスアップ・SEO対策</h2>
            <p className="lead">
              アクセス解析によるSEOキーワードの選定からテキストのリライト、HTML構造の修正などによる検索順位の向上のほか、リッチリザルトによる検索結果の見え方改善など、アクセスアップのためにプロだからできることがあります。<br / >
            </p>
          </SectionDetail>
          <SectionDetail>
            <h2 className="title">SNS・WEBマーケティング</h2>
            <p className="lead">
              InstagramなどSNS、YahooやGoogle、アフィリエイトへの広告出稿のほか、マーケティング戦略の立案・PDCAなどをおこない、結果を出すお手伝いをします。
            </p>
          </SectionDetail>
        </div>
      </MainWrap>
      <Contact />
    </Layout>
  )
}
export default Operate
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
        description
      }
    }
  }
`
